<template>
  <v-row
    align="center"
    justify="center">
    <v-col
      cols="12"
      sm="10">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>
            {{ $t("crudActions.indexModel", {model: $t("models.groups.multiple")}) }}
          </span>
          <v-btn
            small
            color="primary"
            :to="{ name: $routes.group.createRoute.name }">
            {{ $t("crudActions.createModel", {model: $t("models.groups.single")}) }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <data-table
            :loading="loading"
            :headers="tableColumns"
            :items="groups">
            <template #item.active="{ item }">
              <v-switch
                v-model="item.active"
                inset
                @change="toggleActive(item)"/>
            </template>
            <template #item.action="{ item }">
              <v-btn
                icon
                @click="editGroup(item)">
                <font-awesome-icon icon="pencil-alt"/>
              </v-btn>
              <v-btn
                v-if="hasPrivileges(Privilege.loginAs) && item.rootId !== $store.getters.userId"
                color="primary"
                icon
                small
                @click="signInAsGroup(item)">
                <font-awesome-icon icon="sign-in-alt"/>
              </v-btn>
            </template>
          </data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {fetchGroups, updateGroupActiveStatus} from "@/api/group.js";
import ContentLoader from "../../mixins/ContentLoader.js";
import {i18n} from "@/i18n";
import {LOGIN_USER as LOGIN_ACTION} from "@/store/actions.js";
import SessionCreateCategory from "@/imported/SessionCreateCategory.json";
import LoginRedirectionHelper from "@/services/LoginRedirectionHelper.js";
import Privilege from "@/imported/Privilege";
import DataTable from "@/components/DataTable.vue";

export default {
  name: "GroupIndex",
  components: { DataTable },
  mixins: [ContentLoader],
  data() {
    return {
      groups: [],
      Privilege
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          value: "id",
          text: "#",
        },
        {
          value: "name",
          text: i18n.t("attributes.groups.name"),
        },
        {
          value: "active",
          text: i18n.t("attributes.groups.active"),
        },
        {
          value: "action",
          text: i18n.t("actions.text"),
        },
      ];
    },
  },
  async mounted() {
    this.$startLoading();

    try {
      const {
        data: {groups},
      } = await fetchGroups();

      this.groups = groups;

      await this.$nextTick();
    } finally {
      this.$finishedLoading();
    }
  },
  methods: {
    editGroup({id}) {
      this.$router.push({
        name: this.$routes.group.editRoute.name,
        params: {id},
      });
    },
    toggleActive(group) {
      updateGroupActiveStatus(group.id, group.active)
        .then(() => console.log(`Group ${group.id} status updated!`))
        .catch((error) => {
          console.error(error);
          group.active = !group.active;
        });
    },
    hasPrivileges(privileges) {
      const userPrivileges = this.$store.getters.privileges;

      if (privileges instanceof Array) {
        return privileges.some((p) => userPrivileges.includes(p));
      }

      return userPrivileges.includes(privileges);
    },
    signInAsGroup(group) {
      const {$store, $route, $routes, $router} = this,
        form = {
          id: group.rootId,
          current_user_token: $store.state.token,
        };

      $store.dispatch(LOGIN_ACTION, {form, category: SessionCreateCategory.loginAs}).then(token => {
        this.disconnectWebsocket();
        this.connectWebsocket({ token: `Bearer ${token}` })
        LoginRedirectionHelper.getNextPath($router, $route, $routes, $store);
      });
    }
  },
};
</script>
