import SessionCreateCategory from "@/imported/SessionCreateCategory.json";

export function getNextPath($router, $route, $routes, $store) {
  const {
    bettingPool,
    sessionMode,
  } = $store.state;

  if ($route.query.continue) {
    return $route.query.continue;
  } else {
    if (bettingPool) {
      if (sessionMode === SessionCreateCategory.user) {
        return { name: $routes.ticket.createRoute.name };
      } else {
        return { name: $routes.roulette.boardRoute.name };
      }
    } else {
      return { name: $routes.home.dashboardRoute.name };
    }

  }
}

export default {
  getNextPath($router, $route, $routes, $store) {
    const {
      bettingPool,
      sessionMode,
    } = $store.state;

    if ($route.query.continue) {
      $router.push($route.query.continue);
    } else {
      if (bettingPool) {
        if (sessionMode === SessionCreateCategory.user) {
          $router.push({ name: $routes.ticket.createRoute.name });
        } else {
          $router.push({ name: $routes.roulette.boardRoute.name });
        }
      } else {
        $router.push({ name: $routes.home.dashboardRoute.name });
      }

    }
  },
};
